import Vue from "vue";
import Router from "vue-router";
import AppHome from "./views/AppHome.vue";
import EmpTimesheet from "./views/EmpTimesheet.vue";
import EmpExpense from "./views/EmpExpense.vue";
import EditExpense from "./views/EditExpense.vue";
import VehicleMaintenance from "./views/VehicleMaintenance.vue";
import AdminHome from "./views/AdminHome.vue";
import AdminClientAdd from "./views/AdminClientAdd.vue";
import AdminClientEdit from "./views/AdminClientEdit.vue";
import AdminProjectAdd from "./views/AdminProjectAdd.vue";
import AdminProjectEdit from "./views/AdminProjectEdit.vue";
import AdminVehicleAdd from "./views/AdminVehicleAdd.vue";
import EmpTimesheetReview from "./views/EmpTimesheetReview.vue";
import AdminStaffReview from "./views/AdminStaffReview.vue";
import EditVehicleStatus from "./views/EditVehicleStatus.vue";
import AdminAddPO from "./views/AdminPOAdd.vue";
import AdminAddBank from "./views/AdminAddBank.vue";
import AdminTimesheetReview from "./views/AdminTimesheetReview.vue";
import AdminAddExpense from "./views/AdminAddExpense.vue";
import AdminFleetMgmt from "./views/AdminFleetMgmt.vue";
import Doc from "./pages/documentation/Overview.vue";
import DocAddTimesheet from "./pages/documentation/DocAddTimesheet.vue";
import DocEditTimesheet from "./pages/documentation/DocEditTimesheet.vue";
import DocAddExpense from "./pages/documentation/DocAddExpense.vue";
import Profile from "./pages/profile/Index.vue";
import Onboarding from "./pages/onboarding/Index.vue";
import OnboardingEmail from "./pages/onboarding/Email.vue";
import CodeOfConduct from "./pages/onboarding/CodeOfConduct.vue";
import CompanyValues from "./pages/onboarding/CompanyValues.vue";
import SkillsMatrix from "./views/SkillsMatrix.vue";
import ErpTime from "./views/ErpTime.vue";
import ErpExpenses from "./views/ErpExpenses.vue";
import ErpOvertime from "./views/ErpOvertime.vue";
import AzureCost from "./views/AzureCost.vue";
import Office365 from "./views/Office365.vue";
import PaginatedReport from "./views/PaginatedReport.vue";
import Hiring from "./views/AdminAddHiringResume.vue";
import RequestForm from "./views/EmpLeave.vue";
import DocTimesheetReview from "./pages/documentation/DocTimesheetReview.vue";
import Home from "./views/Home.vue";
import UserHelp from "./views/UserHelp.vue";
import SecurityPolicy from "./pages/onboarding/SecurityPolicy.vue";
import EmpRoster from "./pages/documentation/EmpRoster.vue";
// import ErpTimeAndExpenes from './views/ErpTimeAndExpenes.vue'
// import UserDoc from './pages/documentation/user/Overview.vue'
import store from "./store";
import ComputerSpecs from "./pages/onboarding/ComputerSpecs.vue";
import MonitorSpecs from "./pages/onboarding/MonitorSpecs.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    // {
    //   path: "/home",
    //   name: "home",
    //   component: Home
    // },
    {
      path: "/home",
      name: "app-home",
      component: function () {
        const user = store.getters.getLoggedInUser;
        return new Promise((resolve) =>
          resolve(user && user.isAdmin ? AdminHome : AppHome)
        );
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile
    },
    {
      path: "/onboarding",
      name: "onboarding",
      component: Onboarding
    },
    {
      path: "/empRoster",
      name: "empRoster",
      component: EmpRoster
    },
    {
      path: "/onboardingEmail",
      name: "onboardingEmail",
      component: OnboardingEmail
    },
    {
      path: "/securityPolicy",
      name: "securityPolicy",
      component: SecurityPolicy
    },
    {
      path: "/codeOfConduct",
      name: "codeOfConduct",
      component: CodeOfConduct
    },
    {
      path: "/companyValues",
      name: "companyValues",
      component: CompanyValues
    },
    {
      path: "/computerSpecs",
      name: "computerSpecs",
      component: ComputerSpecs
    },
    {
      path: "/monitorSpecs",
      name: "monitorSpecs",
      component: MonitorSpecs
    },
    {
      path: "/timesheets/create",
      name: "emp-add-timesheet",
      component: EmpTimesheet
    },
    {
      path: "/expenses/create",
      name: "emp-add-expense",
      component: EmpExpense
    },
    {
      path: "/expenses/edit/:id",
      name: "emp-edit-expense",
      component: EditExpense
    },
    {
      path: "/leave/leave-request",
      name: "emp-leave-request",
      component: RequestForm
    },
    {
      path: "/vehicle-maintenance",
      name: "emp-vehicle-maintenance",
      component: VehicleMaintenance
    },
    {
      path: "/admin/clients/create",
      name: "admin-client-add",
      component: AdminClientAdd
    },
    {
      path: "/admin/clients/edit",
      name: "admin-client-edit",
      component: AdminClientEdit
    },
    {
      path: "/admin/projects/create",
      name: "admin-project-add",
      component: AdminProjectAdd
    },
    {
      path: "/admin/vehicles/review",
      name: "admin-vehicle-review",
      component: AdminVehicleAdd
    },
    {
      path: "/verify-review",
      name: "emp-verify-review",
      component: EmpTimesheetReview
    },
    {
      path: "/admin/staffs/review",
      name: "admin-staff-review",
      component: AdminStaffReview
    },

    {
      path: "/admin/projects/edit",
      name: "admin-project-edit",
      component: AdminProjectEdit
    },
    {
      path: "/admin/vehicles/edit",
      name: "admin-vehicle-edit",
      component: EditVehicleStatus
    },
    {
      path: "/admin/add-new-po",
      name: "admin-add-new-po",
      component: AdminAddPO
    },
    {
      path: "/admin/add-new-bank",
      name: "admin-add-new-bank",
      component: AdminAddBank
    },
    {
      path: "/admin/timesheets/review",
      name: "admin-timesheet-review",
      component: AdminTimesheetReview
    },
    {
      path: "/admin/expenses/create",
      name: "admin-expenses-add",
      component: AdminAddExpense
    },
    {
      path: "/admin/fleet-mgt",
      name: "admin-fleet-mgt",
      component: AdminFleetMgmt
    },
    {
      path: "/admin/skillsMatrix",
      name: "skills-matrix",
      component: SkillsMatrix
    },
    {
      path: "/admin/erpTime",
      name: "erp-time-and-expenses",
      component: ErpTime
    },
    {
      path: "/admin/erpExpenses",
      name: "erp-expenses",
      component: ErpExpenses
    },
    {
      path: "/admin/erpOvertime",
      name: "erp-overtime",
      component: ErpOvertime
    },
    {
      path: "/admin/azureCost",
      name: "azure-cost",
      component: AzureCost
    },
    {
      path: "/admin/office365",
      name: "office-365",
      component: Office365
    },
    {
      path: "/admin/hr/hiring",
      name: "hiring",
      component: Hiring
    },
    {
      path: "/admin/testpage",
      name: "test-page",
      component: PaginatedReport
    },
    // {
    //     path: '/erpTime',
    //     name: 'erp-time-and-expenses',
    //     component: ErpTimeAndExpenes
    // },
    // {
    //   path: '/documentation/user',
    //   name: 'user-documentation',
    //   component: UserDoc
    // },
    {
      path: "/documentation",
      name: "documentation",
      component: Doc
    },
    {
      path: "/documentation/add-timesheet",
      name: "doc-add-timesheet",
      component: DocAddTimesheet
    },
    {
      path: "/documentation/edit-timesheet",
      name: "doc-edit-timesheet",
      component: DocEditTimesheet
    },
    {
      path: "/documentation/add-expense",
      name: "doc-add-expense",
      component: DocAddExpense
    },
    {
      path: "/user-help",
      name: "user-help",
      component: UserHelp
    },
    {
      path: "/documentation/timesheet-review",
      name: "doc-add-expense",
      component: function () {
        const user = store.getters.getLoggedInUser;
        return new Promise((resolve) =>
          resolve(user && user.isAdmin ? DocTimesheetReview : AppHome)
        );
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const currentUser = store.getters["getLoggedInUser"];

  if (
    (to.path.indexOf("admin") >= 0 && currentUser && !currentUser.isAdmin) ||
    (to.path !== "/" && currentUser === null)
  ) {
    next("/");
  } else {
    next();
  }
});

export default router;
