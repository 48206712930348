<template>
  <div>
    <div class="d-flex">
      <h1 class="mt-3 ml-3">Employee - Project Roster</h1>
      <b-button
        v-if="canEdit"
        @click="saveData"
        variant="primary"
        class="ml-auto mr-3 mt-3"
        :disabled="loading"
      >
        Save
      </b-button>
    </div>
    <div class="tableDiv">
      <table>
        <thead>
          <tr>
            <th class="sticky-col">Employee Name</th>
            <td v-for="project in projects" :key="project.ProjectID" class="project-name">
              {{ project.ProjectName }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in employees" :key="employee.id">
            <td class="employee-name sticky-col">{{ employee.Name }}</td>
            <td v-for="project in projects" :key="project.ProjectID" class="checkbox-cell">
              <input
                type="checkbox"
                :checked="isAllowedToBill(employee.id, project.ProjectID)"
                v-if="canEdit"
                @change="updateIsAllowedToBill(employee.id, project.ProjectID)"
              />
              <span v-else>
                {{
                  isAllowedToBill(employee.id, project.ProjectID) ? "Yes" : ""
                }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    loading: {
      default: false
    }
  },
  data() {
    return {
      employees: [],
      projects: [],
      isAllowedToBillData: [],
      updatedIsAllowedToBillData: []
    };
  },
  mounted() {
    this.getEmployees();
    this.getProjects();
    this.getRoaster();
  },
  computed: {
    canEdit() {
      return this.currentUser.username === "brian.kean@insight-awp.com" ||
        this.currentUser.username === "gregorio.labbozzetta@insight-awp.com" ||
        this.currentUser.username === "navjot.randhawa@insight-awp.com"
        ? true
        : false;
    },
    ...mapGetters({
      currentUser: "getLoggedInUser"
    })
  },
  methods: {
    async getEmployees() {
      this.setLoading(true);
      try {
        const res = await this.$axios.get(`/activeStaffs`);
        if (res && res.data.length > 0) {
          this.employees = res.data;
        }
        this.setLoading(false);
      } catch (err) {
        console.error(err);
      }
    },
    async getProjects() {
      this.setLoading(true);
      try {
        const res = await this.$axios.get(`/projects?sort_by=ProjectName`);
        if (res && res.data.length > 0) {
          this.projects = res.data;
        }
        this.setLoading(false);
      } catch (err) {
        console.error(err);
      }
    },
    async getRoaster() {
      try {
        const res = await this.$axios.get(`/peRoaster`);
        if (res && res.data.length > 0) {
          const data = res.data;
          let newData = [];
          for (let i = 0; i < data.length; i++) {
            newData.push({
              employeeId: data[i].empId,
              projectId: data[i].projectId,
              isAllowedToBill: data[i].canBillHours
            });
          }
          this.isAllowedToBillData = newData;
        }
      } catch (err) {
        console.error(err);
      }
    },
    isAllowedToBill(employeeId, projectId) {
      const record = this.isAllowedToBillData.find(
        (entry) =>
          entry.employeeId === employeeId && entry.projectId === projectId
      );
      return record ? record.isAllowedToBill : false;
    },
    updateIsAllowedToBill(employeeId, projectId) {
      const record = this.isAllowedToBillData.find(
        (ent) => ent.employeeId === employeeId && ent.projectId === projectId
      );
      const wasUpdated = this.updatedIsAllowedToBillData.find(
        (ent) => ent.employeeId === employeeId && ent.projectId === projectId
      );
      if (wasUpdated) {
        //remove the record from updatedIsAllowedToBillData
        this.updatedIsAllowedToBillData =
          this.updatedIsAllowedToBillData.filter(
            (ent) =>
              ent.employeeId !== employeeId || ent.projectId !== projectId
          );
      } else {
        if (record) {
          this.updatedIsAllowedToBillData.push({
            employeeId,
            projectId,
            isAllowedToBill: !record.isAllowedToBill
          });
        } else {
          this.updatedIsAllowedToBillData.push({
            employeeId,
            projectId,
            isAllowedToBill: true
          });
        }
      }
      console.log("updated Data", this.updatedIsAllowedToBillData);
    },
    async saveData() {
      this.setLoading(true);
      try {
        await this.$axios.post(`/peRoaster`, this.updatedIsAllowedToBillData);
      } catch (err) {
        let data = err.response && err.response.data;
        if (data && data.message) {
          return this.displayError(data.message);
        }
        this.displayError("Failed to save Roaster record, please try later.");
      } finally {
        this.setLoading(false);
      }
      this.makeToast("success", "Roaster has been saved!");
      window.location.reload();
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border: 0;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

.employee-name, .project-name {
  white-space: nowrap; /* Prevent wrapping */
}

.checkbox-cell input[type="checkbox"] {
  margin: auto; /* Center checkbox */
  display: block;
}

.tableDiv {
  overflow: auto;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  max-height: 81vh;

  /* change overflow sliders to small */
  scrollbar-width: thin;
  scrollbar-color: #dddddd #f9f9f9;
}

thead {
  z-index: 100;
  background-color: #f2f2f2
}

th {
  background-color: #f2f2f2;
  position: sticky;
  left: 0;
  top: 0 !important;
  z-index: 100;
  border-left: 0;
}

thead td,
thead th {
  border-top: 0;
}

td:first-child {
  position: sticky;
  left: 0;
  background-color: #f9f9f9; /* Change as needed */
  z-index: 99;
  border-left: 0;
}

table {
  opacity: 0;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; /* Fade-in animation */
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

tr:hover {
  background-color: #f5f5f5; /* Hover effect for rows */
}

.checkbox-cell input[type="checkbox"] {
  margin: auto;
  display: block;
  transition: transform 0.2s ease-in-out; /* Smooth transition for checkbox */
}

.checkbox-cell input[type="checkbox"]:checked {
  transform: scale(1.2); /* Slightly enlarge checked checkboxes */
}
</style>
