<template>
  <!-- Sidebar -->
  <div
    class="bg-light border-right"
    id="sidebar-wrapper"
    style="font-size: 14px"
  >
    <div class="logo" @click="$router.push({ name: 'home' })">
      <img src="../assets/images/logo-insight-awp.png" style="width: 127px" />
    </div>
    <div
      v-if="isLoggedIn"
      class="d-flex flex-column align-content-between flex-wrap"
    >
      <!-- v-if="!$route.path.includes('documentation')" -->
      <div
        id="sideBarDiv"
        class="d-flex flex-column align-content-between flex-wrap"
      >
        <ul class="list-group">
          <router-link
            :to="{ name: 'app-home' }"
            class="bg-green text-dark text-decoration-none"
            ><li class="list-group-item bg-transparent">
              Dashboard
            </li></router-link
          >

          <!--<button class="dropdown-btn list-group-item list-group-item-action bg-light">-->
          <span
            class="cursor-pointer list-group-item list-group-item-action font-weight-bold"
            v-b-toggle.collapse-1
          >
            Contractor <i class="fa fa-caret-down"></i>
          </span>
          <b-collapse id="collapse-1" visible>
            <router-link
              :to="{ name: 'emp-add-timesheet' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Add Timesheet
              </li></router-link
            >
            <router-link
              :to="{ name: 'emp-add-expense' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Add Expense
              </li></router-link
            >
            <router-link
              :to="{ name: 'emp-leave-request' }"
              class="bg-green text-dark text-decoration-none"
            >
              <li class="list-group-item pl-5 bg-inherit">Leave Form</li>
            </router-link>
            <!-- <router-link
              :to="{ name: 'emp-verify-review' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Verify Timesheet & Expense
              </li></router-link
            > -->

            <!-- <router-link :to="{ name: 'emp-vehicle-maintenance' }" class="list-group-item list-group-item-action bg-green">Vehicle Maintenance</router-link> -->
            <!--<button class="dropdown-btn list-group-item list-group-item-action bg-green"-->
          </b-collapse>

          <span
            class="cursor-pointer dropdown-btn list-group-item list-group-item-action font-weight-bold"
            v-b-toggle.collapse-3
          >
            Human Resources <i class="fa fa-caret-down"></i>
          </span>
          <b-collapse id="collapse-3">
            <router-link
              :to="{ name: 'profile' }"
              class="bg-green text-dark text-decoration-none"
            >
              <li class="list-group-item pl-5 bg-inherit">Employee Profile</li>
            </router-link>
            <span
              class="cursor-pointer dropdown-btn list-group-item list-group-item-action font-weight-bold"
              v-b-toggle.onBoardingCollapse
            >
              Onboarding <i class="fa fa-caret-down"></i>
            </span>
            <b-collapse id="onBoardingCollapse">
              <router-link
                :to="{ name: 'onboardingEmail' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Email
                </li></router-link
              >
              <router-link
                :to="{ name: 'securityPolicy' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  ESET Security Policy
                </li></router-link
              >
              <router-link
                :to="{ name: 'onboarding' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Info
                </li></router-link
              >
              <router-link
                :to="{ name: 'codeOfConduct' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Code Of Conduct
                </li></router-link
              >
              <router-link
                :to="{ name: 'companyValues' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Company Values
                </li></router-link
              >
              <router-link
                :to="{ name: 'computerSpecs' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Computer Specs
                </li></router-link
              >
              <router-link
                :to="{ name: 'monitorSpecs' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Standard Monitors
                </li></router-link
              >
            </b-collapse>

            <span
              class="cursor-pointer dropdown-btn list-group-item list-group-item-action font-weight-bold"
              v-b-toggle.collapse-5
            >
              Skill Surveys <i class="fa fa-caret-down"></i>
            </span>
            <b-collapse id="collapse-5">
              <a
                href="https://forms.microsoft.com/r/VBBJWQ5MdV"
                target="_blank"
              >
                <li
                  role="button"
                  class="list-group-item pl-5 bg-inherit bg-green text-dark text-decoration-none"
                >
                  AWP/WFP/Management
                </li>
              </a>

              <a
                href="https://forms.microsoft.com/r/PdDDdPXeKj"
                target="_blank"
              >
                <li
                  role="button"
                  class="list-group-item pl-5 bg-inherit bg-green text-dark text-decoration-none"
                >
                  Information Management
                </li>
              </a>

              <a
                href="https://forms.microsoft.com/r/6usrEE1k0j"
                target="_blank"
              >
                <li
                  role="button"
                  class="list-group-item pl-5 bg-inherit bg-green text-dark text-decoration-none"
                >
                  Construction
                </li>
              </a>

              <a
                href="https://forms.microsoft.com/r/qY64Hv0XFN"
                target="_blank"
              >
                <li
                  role="button"
                  class="list-group-item pl-5 bg-inherit bg-green text-dark text-decoration-none"
                >
                  Data Management
                </li>
              </a>
            </b-collapse>
          </b-collapse>

          <span
            v-if="isAdmin"
            class="cursor-pointer dropdown-btn list-group-item list-group-item-action font-weight-bold"
            v-b-toggle.collapse-2
          >
            Admin <i class="fa fa-caret-down"></i>
          </span>
          <b-collapse id="collapse-2" visible>
            <!--<div class="dropdown-container">-->
            <!-- <router-link v-if="isAdmin" :to="{ name: 'app-home' }" class="list-group-item list-group-item-action bg-green">Client Administration
              <span class="badge">3</span>
            </router-link> -->
            <!-- <router-link v-if="isAdmin" :to="{ name: 'admin-client-edit' }" class="list-group-item list-group-item-action bg-green">Clients</router-link> -->
            <!-- <router-link v-if="isAdmin" :to="{ name: 'admin-vehicle-edit' }" class="list-group-item list-group-item-action bg-green">Edit Vehicle Status</router-link> -->
            <!-- <router-link v-if="isAdmin" :to="{ name: 'admin-add-new-po' }" class="list-group-item list-group-item-action bg-green">Add New PO</router-link> -->
            <!-- <router-link v-if="isAdmin" :to="{ name: 'admin-add-new-bank' }" class="list-group-item list-group-item-action bg-green">Add New Bank</router-link> -->
            <router-link
              v-if="isAdmin"
              :to="{ name: 'admin-timesheet-review' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Timesheet Review
              </li></router-link
            >
            <router-link
              v-if="isAdmin"
              :to="{ name: 'admin-project-add' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Add New Project
              </li></router-link
            >
            <router-link
              v-if="isAdmin"
              :to="{ name: 'empRoster' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Employee Roster
              </li></router-link
            >
            <router-link
              v-if="isAdmin"
              :to="{ name: 'admin-vehicle-review' }"
              class="bg-green text-dark text-decoration-none"
            >
              <li class="list-group-item pl-5 bg-inherit">
                Vehicle Review
              </li></router-link
            >
            <router-link
              v-if="isAdmin"
              :to="{ name: 'admin-staff-review' }"
              class="bg-green text-dark text-decoration-none"
            >
              <li class="list-group-item pl-5 bg-inherit">
                Admin Settings
              </li></router-link
            >

            <span
              v-if="isAdmin"
              class="cursor-pointer dropdown-btn list-group-item list-group-item-action font-weight-bold"
              v-b-toggle.collapse-6
            >
              Human Resources <i class="fa fa-caret-down"></i>
            </span>
            <b-collapse id="collapse-6">
              <router-link
                :to="{ name: 'hiring' }"
                class="bg-green text-dark text-decoration-none"
              >
                <li class="list-group-item pl-5 bg-inherit">Resume</li>
              </router-link>
            </b-collapse>

            <span
              v-if="isAdmin"
              class="cursor-pointer dropdown-btn list-group-item list-group-item-action font-weight-bold"
              v-b-toggle.collapse-4
            >
              Power BI Reports <i class="fa fa-caret-down"></i>
            </span>
            <b-collapse id="collapse-4">
              <router-link
                v-if="isAdmin"
                :to="{ name: 'erp-time-and-expenses' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Timesheets
                </li></router-link
              >

              <router-link
                v-if="isAdmin"
                :to="{ name: 'erp-overtime' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Home Office Hours
                </li></router-link
              >

              <router-link
                v-if="isAdmin"
                :to="{ name: 'erp-expenses' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Expenses
                </li></router-link
              >

              <router-link
                v-if="isAdmin"
                :to="{ name: 'office-365' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Office 365
                </li></router-link
              >

              <router-link
                v-if="isAdmin"
                :to="{ name: 'azure-cost' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Azure Cost
                </li></router-link
              >

              <router-link
                v-if="isAdmin"
                :to="{ name: 'skills-matrix' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Skills Matrix
                </li></router-link
              >

              <!-- <router-link
              v-if="isAdmin"
              :to="{ name: 'test-page' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
               Test
              </li></router-link
            > -->
            </b-collapse>
          </b-collapse>

          <span
            class="cursor-pointer dropdown-btn list-group-item list-group-item-action font-weight-bold"
            v-b-toggle.collapse-8
          >
            Documentation <i class="fa fa-caret-down"></i>
          </span>
          <b-collapse id="collapse-8">
            <router-link
              :to="{ name: 'documentation' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Overview
              </li></router-link
            >

            <span
              class="cursor-pointer list-group-item list-group-item-action font-weight-bold"
              v-b-toggle.collapse-7
            >
              How To <i class="fa fa-caret-down"></i>
            </span>
            <b-collapse id="collapse-7" visible>
              <router-link
                :to="{ name: 'doc-add-timesheet' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Add Timesheet
                </li></router-link
              >
              <router-link
                :to="{ name: 'doc-edit-timesheet' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Edit Timesheet
                </li></router-link
              >
              <router-link
                :to="{ name: 'doc-add-expense' }"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Add Expense
                </li></router-link
              >
              <router-link
                v-if="isAdmin"
                to="/documentation/timesheet-review"
                class="bg-green text-dark text-decoration-none"
                ><li class="list-group-item pl-5 bg-inherit">
                  Timesheet Review
                </li></router-link
              >
            </b-collapse>

            <!-- <router-link
              v-if="isAdmin"
              :to="{ name: 'test-page' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
               Test
              </li></router-link
            > -->
            <!-- ------------------- -->

            <!-- <router-link v-if="isAdmin" :to="{ name: 'admin-expenses-add' }" class="list-group-item list-group-item-action bg-green">Admin Expenses</router-link> -->
            <!-- <router-link v-if="isAdmin" :to="{ name: 'admin-fleet-mgt' }" class="list-group-item list-group-item-action bg-green">Fleet Management</router-link> -->
            <!--</div>

            <router-link :to="{ name: 'admin-client-add' }" class="list-group-item list-group-item-action bg-green">Notifications</router-link>-->
          </b-collapse>

          <!-- <router-link
            :to="{
              name: 'documentation'
            }"
            class="bg-green text-dark"
          >
            <li class="list-group-item bg-transparent">Documentation</li>
          </router-link> -->
        </ul>
      </div>
      <!-- <div v-else>
        <ul class="list-group">
          <router-link
            :to="{ name: 'documentation' }"
            class="bg-green text-dark text-decoration-none"
            ><li class="list-group-item bg-transparent">
              Overview
            </li></router-link
          >
          <span
            class="cursor-pointer list-group-item list-group-item-action font-weight-bold"
            v-b-toggle.collapse-1
          >
            How To <i class="fa fa-caret-down"></i>
          </span>
          <b-collapse id="collapse-1" visible>
            <router-link
              :to="{ name: 'doc-add-timesheet' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Add Timesheet
              </li></router-link
            >
            <router-link
              :to="{ name: 'doc-edit-timesheet' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Edit Timesheet
              </li></router-link
            >
            <router-link
              :to="{ name: 'doc-add-expense' }"
              class="bg-green text-dark text-decoration-none"
              ><li class="list-group-item pl-5 bg-inherit">
                Add Expense
              </li></router-link
            >
          </b-collapse>
        </ul>
      </div> -->
    </div>
  </div>
  <!-- End Sidebar-->
</template>

<script>
// External libraries and components imported
import { mapGetters } from "vuex";

export default {
  // computed properties
  computed: {
    isAdmin() {
      return this.isLoggedIn && this.currentUser.isAdmin;
    },

    isLoggedIn() {
      return this.currentUser !== null;
    },

    // mix the getters into computed with object spread operator
    ...mapGetters({
      currentUser: "getLoggedInUser"
    })
  }
};
</script>

<style scoped>
.bg-green:hover {
  background: #72a02e;
  color: #fff !important;
}
.bg-inherit {
  background-color: inherit;
}
#sidebar-wrapper .list-group-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.cursor-pointer {
  cursor: pointer;
}

a {
  color: rgba(0, 0, 0, 0.8);
}
#sideBarDiv {
  overflow: scroll;
  height: 90.5vh;
  -ms-overflow-style: none; /* Internet Explorer */
  scrollbar-width: none; /* FireFox */
}

#sideBarDiv::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.logo {
  cursor: pointer;
}
</style>
