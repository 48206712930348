<template>
  <div class="table-responsive">
    <table class="table table-hover" id="ipTable">
      <b-thead>
        <tr v-if="filterable">
          <td colspan="100%">
            <vc-date-picker v-model="range" is-range>
              <template v-slot="{ inputValue, inputEvents, updateValue }">
                <div class="d-flex justify-content-center">
                  <div class="align-content-end d-grid mx-4">
                    <b-button-group>
                      <b-dropdown right variant="success" text="Quick Filters">
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilterTime(0, 'month'));
                            applyFilter();
                          "
                          >Current Month</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilterTime(1, 'month'));
                            applyFilter();
                          "
                          >Previous Month</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilter(3, 'months'));
                            applyFilter();
                          "
                          >Last 3 Months</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilter(6, 'months'));
                            applyFilter();
                          "
                          >Last 6 Months</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilter(1, 'year'));
                            applyFilter();
                          "
                          >Last 1 Year</b-dropdown-item
                        >
                      </b-dropdown>
                    </b-button-group>
                  </div>
                  <div class="w-50">
                    Filter Records:
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text>
                          <i class="fa fa-calendar"></i
                        ></b-input-group-text>
                      </template>
                      <template #append>
                        <b-button
                          class="w-100"
                          variant="primary"
                          :disabled="!is_modified"
                          @click="applyFilter"
                          >Apply Filter</b-button
                        >
                      </template>
                      <b-form-input
                        readonly
                        class="bg-light"
                        :value="`${inputValue.start} - ${inputValue.end}`"
                        v-on="inputEvents.start"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>
              </template>
            </vc-date-picker>
          </td>
        </tr>
        <slot name="thead"></slot>
      </b-thead>
      <b-tbody>
        <slot name="tbody" v-if="$slots.tbody && !is_processing"></slot>
        <tr v-else>
          <td colspan="100%" class="text-center">Records not found</td>
        </tr>
      </b-tbody>
      <b-tfoot>
        <slot name="tfoot" v-if="$slots.tbody"></slot>
      </b-tfoot>
    </table>
  </div>
</template>

<script>
import { is } from "vee-validate/dist/rules";

export default {
  name: "Table",
  props: {
    // If filterable is set/passed, only then the table will show the filter options.
    filterable: {
      default: false,
      type: Boolean
    },
    // is_processing hides the records.
    is_processing: {
      default: false,
      type: Boolean
    },
    bus: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      is_modified: false, // It is used to indicate if the apply filter button should be enable or not.
      range: {
        start: this.$moment().startOf("month").toDate(),
        end: this.$moment().endOf("month").toDate()
      },
      is_project_selected: false
    };
  },
  methods: {
    // This method emits the apply-filter event, which can be handled in the parent component.
    // It also gives start and end date to the parent component
    // Here we will disable the "Apply Filter button" once clicked.
    applyFilter() {
      if (this.is_project_selected) {
        console.log("project is selected: ", this.is_project_selected);
        this.makeToast(
          "warning",
          "Clearing project filter to apply date filter."
        );
        this.$set(this, "is_project_selected", false);
      }
      this.formatRange();
      setTimeout(() => this.$set(this, "is_modified", false), 100);
      this.$emit("apply-filter", this.range);
    },

    // quickFilter accepts: amount and time
    // amount: amount of time needs to go back to the past (1,2,3) etc. refer moment doc.,
    // time: unit of time (i.e. 'months', 'days') etc. refer moment doc.
    // and returns object with start and end properties
    quickFilter(amount, time) {
      return {
        start: this.$moment().subtract(amount, time).toDate(),
        end: new Date()
      };
    },

    // quickFilterTime accepts: amount and time. However, it starts the date from start of the month.
    // amount: amount of time needs to go back to the past (1,2,3) etc. refer moment doc.,
    // time: unit of time (i.e. 'months', 'days') etc. refer moment doc.
    // and returns object with start and end properties
    quickFilterTime(amount, time) {
      let _subtracted = this.$moment().subtract(amount, time);
      return {
        start: _subtracted.startOf(time).toDate(),
        end:
          amount == 0
            ? this.$moment().endOf(time).toDate()
            : _subtracted.endOf(time).toDate()
      };
    },

    // This method converts date into given formatted string.
    toString(date) {
      return this.$moment(date).format("MM/DD/YYYY");
    },

    // This method converts range objects toString().
    formatRange() {
      this.range.start = this.toString(this.range.start);
      this.range.end = this.toString(this.range.end);
    },

    formatBillingRange(billingCycle) {
      console.log("billingCycle is here:  ", billingCycle);
      if (billingCycle.length > 0) {
        this.$set(this, "is_project_selected", true);
      } else {
        this.$set(this, "is_project_selected", false);
      }
      if (billingCycle == '"Fixed Offset (26 to 25)"') {
        // set start date to 26th of previous month
        var start = this.$moment().subtract(1, "months").date(26).toDate();
        // set end date to 25th of current month
        var end = this.$moment().date(25).toDate();

        start = this.toString(start);
        end = this.toString(end);

        this.$set(this, "range", { start, end });
      } else if (billingCycle.length === 0) {
        this.$set(this, "range", {
          start: this.$moment().startOf("month").toDate(),
          end: this.$moment().endOf("month").toDate()
        });
      }
      // console.log("range is here:  ", this.range);
    },

    // This method compares 2 date object and checks if the date has been modified or not.
    haveDatesModified(_new, _old) {
      if (_new && !_old) {
        return true;
      }
      return !(
        this.toString(_new.start) === this.toString(_old.start) &&
        this.toString(_new.end) === this.toString(_old.end)
      );
    }
  },
  mounted() {
    // applyingFilter when the table is mounted,
    // because we want to show the data limited to last 30 days by default.
    this.applyFilter();
    this.bus &&
      this.bus.$on("billingCycle", (billingCycle) =>
        this.formatBillingRange(billingCycle)
      );
  },
  watch: {
    // watching range object and calling haveDatesModified() to check changes,
    // If the date is modified then we will enable the "Apply Filter" button.
    range: {
      handler(_new, _old) {
        if (this.haveDatesModified(_new, _old)) {
          this.is_modified = true;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.d-grid {
  display: grid;
}
</style>
